import * as styles from '../sections/landing1/Home.module.css'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Text, Title, Button } from './Core'
import Link from '../components/Link'
import LsIcon from '../assets/image/svg/lsIcon.inline.svg'


const RenderExternalLink = ({content, externalClass}) => (
  <a
    href={content.btnLink}
    style={{backgroundColor: '#124D95'}}
    className={externalClass}
    target="_self"
    rel="noopener noreferrer"
    aria-label="Trade on Lightspeed">
      {content.btnText}
  </a>
)

const RenderInternalLink = ({content, url}) => (
  <Button style={{backgroundColor: '#124D95'}}>
    <Link to={url}>{content}</Link>
  </Button>
)

const RenderLink = ({content, styles, props}) => (
  props.internalLink ? 
    <RenderInternalLink content={content.internalText} url={props.internalUrl} /> :
    <RenderExternalLink {...{content}} externalClass={styles.aTagButtonSmall} />
)

const LsCTA = props => {
  const { t, ready } = useTranslation(['LsCTA'], { useSuspense: false })
  
  const defatultContent = {
    title:  ready && t('defaultTitle'),
    content: ready && t('defaultContent'),
    btnText: ready && t('defaultBtnText'),
    btnLink: `${process.env.WWW_URL}/auth/login`,
    icon: true,
  }

  const content = props.cta ? props.cta : defatultContent

  return ready && (
    <Container
      className="p-4"
      style={{
        backgroundColor: props.userDarkMode ? '#191B20' : '#FFFFFF',
        border: props.userDarkMode
          ? '1px solid rgba(255, 255, 255, 0.1)'
          : '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      }}
    >
      <Row>
        <Col>
          <Title
            style={{ color: props.userDarkMode ? '#FAFAFA' : 'black' }}
            className="mb-2"
          >
            {content.icon && <LsIcon className="mr-2 mb-1" aria-hidden="true"/>}
            {content.title}
          </Title>
          <Text
            style={{
              color: props.userDarkMode
                ? 'rgba(255, 255, 255, 0.56)'
                : 'rgba(0, 0, 0, 0.56)',
            }}
          >
            {content.content}
          </Text>
        </Col>

        {/* Display Link */}
        <Col sm="12" md="12" lg="auto" className="d-flex align-items-end mt-3 mb-1">
          <RenderLink {...{content}} {...{styles}} props={props.cta} />
        </Col>

      </Row>
    </Container>
  )
}

export default LsCTA
