import React from 'react'
import loadable from '@loadable/component'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import { Section, Text, Title, Button } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import GetStarted from '../sections/landing1/GetStarted'
import LsCTA from '../components/LsCTA'
import setLanguage from '../helpers/languageConfig'

const MarketsTable = loadable(() => import('../components/Table/MarketsTable'))
const ExchMarketsTable = loadable(() =>
  import('../components/Table/ExchMarketsTable')
)

const ExchMarketsTableWithFooter = loadable(() =>
  import('../components/Table/ExchMarketsTableWithFooter')
)

const Markets = props => {
  const { t, ready } = useTranslation(['markets', 'tables'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const totalRecords = useSelector(state => state.exchData.totalRecords)
  const tableRows = useSelector(state => state.user.tableRows)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const cta = {
    title: ready && t('oursTitle'),
    content: ready && t('oursText'),
    btnText: ready && t('getStarted'),
    btnLink: process.env.LS_SIGNUP,
    externalLink: true,
    icon: false,
  }

  return ready && (
    <>
      <MetaData page="markets" />
      <PageWrapper headerDark footerDark>
        <Hero bg={userDarkMode ? 'black' : 'bg'} 
          title={t('heroTitle')}
          firstP={t('marketsText')}>
          {t('marketsIntro')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} py={[0, null, null, 0]}>
          <Container>
            <Row>
              <Col lg="12">
                <LsCTA userDarkMode={userDarkMode} cta={cta} />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row>
              <Col lg="12">
              {
                totalRecords < tableRows ?
                  <ExchMarketsTable
                    title="Lightspeed Crypto markets"
                    exchSlug="lightspeed-crypto"
                    exchCode="LSCX"
                    exchName="Lightspeed Crypto"
                  />
                  :
                  <ExchMarketsTableWithFooter
                    title="Lightspeed Crypto markets"
                    exchSlug="lightspeed-crypto"
                    exchCode="LSCX"
                    exchName="Lightspeed Crypto"
                  />
              }
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg={userDarkMode ? 'black' : 'bg'} py={[0, null, null, 0]}>
          <Container>
            <Row>
              <Col lg="12">
                <Title color={userDarkMode ? '#FAFAFA' : 'black'}>
                  {t('othersTitle')}
                </Title>
                <Text
                  color={
                    userDarkMode
                      ? 'rgba(255, 255, 255, 0.56)'
                      : 'rgba(0, 0, 0, 0.56)'
                  }
                >
                  {t('othersText')}
                </Text>
                <MarketsTable />
              </Col>
            </Row>
          </Container>
        </Section>
        <GetStarted />
      </PageWrapper>
    </>
  )
}

export default Markets
